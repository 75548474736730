import { store } from 'store';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>, offset: number = 68) {
  if (ref && ref.current) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.current.offsetTop - offset },
    ); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export function numberWithPrefix(x?: number, prefix?: string) {
  if (!x) return '-';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, prefix || '.');
}

export const baseURL = (src?: string): string => {
  if (src?.includes('http')) return src;

  if (!src) return '';

  const mediaUrl = store.getState().systems?.mediaUrl;
  return `${mediaUrl?.content || ''}${src || ''}`;
};

export const convertMeterToCentimeter = (value: number) => {
  const meter = Math.floor(value / 100);
  const mod = value % 100;
  return `${meter}m${mod}`;
};

export const renderValue = (val?: number, isPrice?: boolean, prefix?: string) => {
  const converted = val?.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (prefix) return `${converted} ${prefix}`;
  if (isPrice) return converted;
  return val;
};

export const parseString = (val: string | undefined | null) => {
  if (!val) return '';
  return val;
};

export const mappingURLToExternal = (path?: string) => (path ? new URL(path, window.location.origin).href : '');

export const getMeta = (meta?: MetaData) => ({
  page: meta?.page || 0,
  total: meta?.total || 0,
  totalPage: meta?.totalPages || 0,
  isShow: !!meta?.totalPages && meta?.totalPages > 1,
});

export const youtubeParser = (url: string) => {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);
  // eslint-disable-next-line eqeqeq
  return match && match[7].length == 11 ? match[7] : false;
};
export const youtubeControlIframe = (url: string) => `<iframe src= https://www.youtube.com/embed/${youtubeParser(
  url,
)}?autoplay=1&mute=1&loop=1&playlist=${youtubeParser(url)}&controls=1&showinfo=0 frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen="" />`;

export const convertSrcVideo = (src?: string) => {
  if (!src) return '';
  if (youtubeParser(src)) return youtubeControlIframe(src);
  if (src.includes('http')) return src;
  return baseURL(src);
};

export const handleScrollCenter = (
  ref: React.RefObject<HTMLDivElement | HTMLUListElement | null>,
  classNameEleActive: string
) => {
  const eleScroll = ref.current;
  const eleActive = eleScroll && eleScroll.querySelector(classNameEleActive);
  if (!eleActive || !eleScroll) return;
  // get width element scroll
  const widthEleScroll = eleScroll.getBoundingClientRect().width;
  // get distance element scroll compared to y window
  const xEleScroll = eleScroll.getBoundingClientRect().x;
  // get width element active
  const widthEleActive = eleActive.getBoundingClientRect().width;
  // get distance element active compared to y window
  const xEleActive = eleActive.getBoundingClientRect().x;
  // get position sroll bar
  const positionScroll = eleScroll.scrollLeft;
  const scrollX = xEleActive - xEleScroll
    + widthEleActive / 2 + positionScroll - widthEleScroll / 2;
  eleScroll.scroll({
    left: scrollX,
    behavior: 'smooth',
  });
};

export const kFormatter = (num: number) => (Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000))}k` : Math.sign(num) * Math.abs(num));

export const formatValueVoucher = (val: number, type: string) => {
  if (type === 'Amount') {
    return `Giảm ${kFormatter(val)}`;
  }
  return `Giảm ${val}%`;
};

export function removeAccents(x?: string) {
  if (!x) return '';
  return x.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function checkSeoData(seoData?: SEOData) {
  const values = Object.values(seoData || {});
  return values.some((item) => !!item);
}

export const checkSlashLink = (link?: string) => (!link || link?.startsWith('/') || link?.includes('http') ? link : `/${link || ''}`);
