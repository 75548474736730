export const REGION = {
  VN: 1,
};

export const CURRENCY_GLOBAL = 'VND';
export const IMAGE_ATTRIBUTE = 'src="//cms.gumac.vn/storage/upload/media';
export const LOGO_DESKTOP_URL = 'https://js0fpsb45jobj.vcdn.cloud/storage/upload/media/logo/gumac-headerlogo.svg';
export const URL_IMAGE_GOOGLE_NEWS = 'https://js0fpsb45jobj.vcdn.cloud/storage/upload/media/logo/gumacgooglenew.png';
export const LINK_GOOGLE_NEWS = 'https://news.google.com/publications/CAAqBwgKMJOepAwwpZazBA?ceid=VN:vi&oc=3&hl=vi&gl=VN';
export const ERROR_CODE_VOUCHER = {
  6405: 'Voucher không tồn tại trong hệ thống',
  6406: 'Voucher áp dụng không hợp lệ',
  6407: 'Đơn hàng không đủ điền kiện áp dụng voucher'
};
export const SafeQuantityOfInventoryOnline = 5;
export const FB_PIXEL_ID = '2128696120775364';
export const GOOGLE_RECAPTCHA_SITE_KEY = '6LfKVSEqAAAAAGc3rZKHI3IViTWoJJee9AEcsT1D';
